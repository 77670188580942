const BOARD = 'BOARD';
const LABEL = 'LABEL';
const LIST = 'LIST';
const CARD = 'CARD';
const TASK = 'TASK';

export default {
  BOARD,
  LABEL,
  LIST,
  CARD,
  TASK,
};
